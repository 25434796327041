import ContactInfo from "../section/contact/ContactInfo/ContactInfo";
import ContactForm from "../section/contact/ContactForm/ContactForm";

const Contact = () => {
  return (
    <>
    <ContactInfo />
    <ContactForm />
    </>
  )
}

export default Contact;